.bg-image-container {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
	-webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
	backdrop-filter: grayscale(0.5) opacity(0.8);
	z-index: 0;
}
