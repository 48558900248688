.siteWrapperInner {
	background-color: #333;
	display: table-cell;
	vertical-align: top;
	z-index: 10;
}
.coverContainer {
	margin-right: auto;
	margin-left: auto;
	z-index: 10;
}

/* Padding for spacing */
.inner {
	color: white;
	text-align: center;
	padding: 30px;
}

/*
 * Cover
 */

.cover {
	padding: 0 20px;
}

/*
 * Footer
 */

.mastfoot {
	color: #999; /* IE8 proofing */
	color: rgba(255, 255, 255, 0.5);
}

/*
 * Affix and center

 
 */

.siteWrapperInner {
	vertical-align: middle; /* Start the vertical centering */
}

@media (min-width: 768px) {
	/* Pull out the header and footer */
	.masthead {
		position: fixed;
		top: 0;
	}
	.mastfoot {
		position: fixed;
		bottom: 0;
	}

	.masthead,
	.mastfoot,
	.coverContainer {
		width: 540px; /* Must be percentage or pixels for horizontal alignment */
	}
}

@media (max-width: 768px) {
	.masthead {
		position: fixed;
		top: 0;
	}
	.mastfoot {
		position: fixed;
		bottom: 0;
	}

	.masthead,
	.mastfoot,
	.coverContainer {
		width: 70%; /* Must be percentage or pixels for horizontal alignment */
	}
}
