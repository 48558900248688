#page-container {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	min-height: 100vh;
	flex-grow: 1;
}

#page-inner {
	flex-grow: 1;
}

#footer {
	flex-basis: 100px;
	width: 100%;
	background-color: #75797b;
	color: white;
	padding-top: 35px;
	padding-bottom: 35px;
}

.footer-item-holder {
	height: 100%;
	width: 100%;
}

html,
body,
#root {
	position: relative;
	margin: 0;
	padding: 0;
	overflow: auto;
	height: 100%;
}

h1 {
	padding-top: 40px;
}

h2 {
	font-size: 25px;
	color: #0075c9;
}

h3 {
	font-size: 20px;
	color: #0075c9;
}

.sub-header {
	font-size: 30px;
}

.column-body {
	font-size: 16px;
}

.card-title {
	font-size: 18px;
}

.card-title-white {
	font-size: 18px;
	font-weight: bold;
	color: white;
}

.card-body {
	font-size: 14px;
	padding: 0;
}

.small-print {
	font-size: 12px;
}

a:visited,
a:link,
a:hover {
	text-decoration: none;
	color: #fff;
}

/* stylelint-disable property-no-vendor-prefix */

/*
 * Base structure
 */

/* Extra markup and styles for table-esque vertical and horizontal centring */
.site-wrapper {
	display: table;
	width: 100%;
	height: 100vh; /* For at least Firefox */
	min-height: 100%;
	-webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
	overflow: hidden;
}

@media (min-width: 900px) {
	.hero-header {
		font-size: 180px;
		text-align: center;
	}
	.hero-header-project {
		font-size: 75px;
		text-align: center;
	}
	.container-override {
		padding-left: 48px;
		padding-right: 48px;
	}
}

@media (max-width: 900px) {
	.hero-header {
		font-size: 150px;
		text-align: center;
	}
	.hero-header-project {
		font-size: 50px;
		text-align: center;
	}
	.container-override {
		padding-left: 48px;
		padding-right: 48px;
	}
}

@media (max-width: 700px) {
	.hero-header {
		font-size: 100px;
		text-align: center;
	}
	.hero-header-project {
		font-size: 45px;
		text-align: center;
	}
}

@media (max-width: 600px) {
	.footer-item-holder-responsive {
		text-align: center;
		padding-bottom: 30px;
	}
	.sub-header {
		font-size: 25px;
	}

	h2 {
		font-size: 22px;
	}

	h3 {
		font-size: 18px;
	}

	.column-body {
		font-size: 15px;
	}

	.small-print {
		font-size: 10px;
	}

	.card-title {
		font-size: 16px;
	}

	.card-title-white {
		font-size: 16px;
		font-weight: bold;
		color: white;
	}

	.card-body {
		font-size: 13px;
		padding: 0;
		margin: 0;
	}
}
